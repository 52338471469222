// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-en-about-js": () => import("./../../../src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-feature-ai-predictions-js": () => import("./../../../src/pages/en/feature/ai-predictions.js" /* webpackChunkName: "component---src-pages-en-feature-ai-predictions-js" */),
  "component---src-pages-en-feature-analysis-tools-js": () => import("./../../../src/pages/en/feature/analysis-tools.js" /* webpackChunkName: "component---src-pages-en-feature-analysis-tools-js" */),
  "component---src-pages-en-feature-centralizing-your-data-js": () => import("./../../../src/pages/en/feature/centralizing-your-data.js" /* webpackChunkName: "component---src-pages-en-feature-centralizing-your-data-js" */),
  "component---src-pages-en-feature-comparing-data-js": () => import("./../../../src/pages/en/feature/comparing-data.js" /* webpackChunkName: "component---src-pages-en-feature-comparing-data-js" */),
  "component---src-pages-en-feature-data-analysis-js": () => import("./../../../src/pages/en/feature/data-analysis.js" /* webpackChunkName: "component---src-pages-en-feature-data-analysis-js" */),
  "component---src-pages-en-feature-data-driven-growing-training-js": () => import("./../../../src/pages/en/feature/data-driven-growing-training.js" /* webpackChunkName: "component---src-pages-en-feature-data-driven-growing-training-js" */),
  "component---src-pages-en-feature-datahub-js": () => import("./../../../src/pages/en/feature/datahub.js" /* webpackChunkName: "component---src-pages-en-feature-datahub-js" */),
  "component---src-pages-en-feature-iot-sensors-js": () => import("./../../../src/pages/en/feature/iot-sensors.js" /* webpackChunkName: "component---src-pages-en-feature-iot-sensors-js" */),
  "component---src-pages-en-feature-monitoring-tools-js": () => import("./../../../src/pages/en/feature/monitoring-tools.js" /* webpackChunkName: "component---src-pages-en-feature-monitoring-tools-js" */),
  "component---src-pages-en-feature-plant-empowerment-js": () => import("./../../../src/pages/en/feature/plant-empowerment.js" /* webpackChunkName: "component---src-pages-en-feature-plant-empowerment-js" */),
  "component---src-pages-en-features-js": () => import("./../../../src/pages/en/features.js" /* webpackChunkName: "component---src-pages-en-features-js" */),
  "component---src-pages-en-general-terms-and-conditions-js": () => import("./../../../src/pages/en/general_terms_and_conditions.js" /* webpackChunkName: "component---src-pages-en-general-terms-and-conditions-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-login-js": () => import("./../../../src/pages/en/login.js" /* webpackChunkName: "component---src-pages-en-login-js" */),
  "component---src-pages-en-news-js": () => import("./../../../src/pages/en/news.js" /* webpackChunkName: "component---src-pages-en-news-js" */),
  "component---src-pages-en-search-js": () => import("./../../../src/pages/en/search.js" /* webpackChunkName: "component---src-pages-en-search-js" */),
  "component---src-pages-feature-ai-predictions-js": () => import("./../../../src/pages/feature/ai-predictions.js" /* webpackChunkName: "component---src-pages-feature-ai-predictions-js" */),
  "component---src-pages-feature-analysis-tools-js": () => import("./../../../src/pages/feature/analysis-tools.js" /* webpackChunkName: "component---src-pages-feature-analysis-tools-js" */),
  "component---src-pages-feature-centralizing-your-data-js": () => import("./../../../src/pages/feature/centralizing-your-data.js" /* webpackChunkName: "component---src-pages-feature-centralizing-your-data-js" */),
  "component---src-pages-feature-comparing-data-js": () => import("./../../../src/pages/feature/comparing-data.js" /* webpackChunkName: "component---src-pages-feature-comparing-data-js" */),
  "component---src-pages-feature-data-analysis-js": () => import("./../../../src/pages/feature/data-analysis.js" /* webpackChunkName: "component---src-pages-feature-data-analysis-js" */),
  "component---src-pages-feature-data-driven-growing-training-js": () => import("./../../../src/pages/feature/data-driven-growing-training.js" /* webpackChunkName: "component---src-pages-feature-data-driven-growing-training-js" */),
  "component---src-pages-feature-datahub-js": () => import("./../../../src/pages/feature/datahub.js" /* webpackChunkName: "component---src-pages-feature-datahub-js" */),
  "component---src-pages-feature-iot-sensors-js": () => import("./../../../src/pages/feature/iot-sensors.js" /* webpackChunkName: "component---src-pages-feature-iot-sensors-js" */),
  "component---src-pages-feature-monitoring-tools-js": () => import("./../../../src/pages/feature/monitoring-tools.js" /* webpackChunkName: "component---src-pages-feature-monitoring-tools-js" */),
  "component---src-pages-feature-plant-empowerment-js": () => import("./../../../src/pages/feature/plant-empowerment.js" /* webpackChunkName: "component---src-pages-feature-plant-empowerment-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-general-terms-and-conditions-js": () => import("./../../../src/pages/general_terms_and_conditions.js" /* webpackChunkName: "component---src-pages-general-terms-and-conditions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-news-entry-template-en-js": () => import("./../../../src/templates/NewsEntryTemplateEn.js" /* webpackChunkName: "component---src-templates-news-entry-template-en-js" */),
  "component---src-templates-news-entry-template-js": () => import("./../../../src/templates/NewsEntryTemplate.js" /* webpackChunkName: "component---src-templates-news-entry-template-js" */)
}

